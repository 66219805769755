var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.scenario)?_c('div',{staticClass:"main-layout scenario-details",style:({
    background:
      'linear-gradient(180deg, rgba(255,255,255,0), rgba(255, 255,255,1) 20%), url(\'/images/background/' +
      _vm.scenario.color +
      '.jpg\') repeat-x',
  })},[_c('div',{staticClass:"usermenu left"},[_c('a',{staticClass:"link",attrs:{"href":"#"},on:{"mousedown":function($event){$event.preventDefault();return _vm.startOver($event)}}},[_vm._v("Back")])]),(_vm.evaluation)?_c('div',{attrs:{"id":"details-main"}},[_c('h1',[_vm._v(" "+_vm._s(_vm.scenario.title)+": Indicators, baseline and targets for "+_vm._s(_vm.evaluation.years[_vm.evaluation.years.length - 1])+" ")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.description)}}),(_vm.evaluation)?_c('div',{staticClass:"baseline indicators"},_vm._l((_vm.displayedIndicators),function(indicator){return _c('div',{key:indicator,class:'indicator ' + indicator},[_c('div',{staticClass:"heading"},[_c('h4',[_vm._v(_vm._s(_vm.indicators[indicator].name))])]),_c('div',{staticClass:"charts"},[_c('line-chart',{ref:"chart",refInFor:true,staticClass:"chart",attrs:{"chart-data":_vm.evaluation.baselineWithTargetDatacollection(
                indicator,
                _vm.evaluation.baselineDatacollection(indicator, null),
                true
              ),"options":_vm.evaluation.chartOptions(indicator, true),"width":425,"height":205}})],1)])}),0):_vm._e(),_c('h3',[_vm._v("Targets to address the problems")]),(_vm.evaluation)?_c('div',{staticClass:"targets"},[_vm._l((_vm.displayedIndicators),function(indicator,index){return _c('div',{key:index,class:'indicator ' + indicator},[_c('img',{attrs:{"src":'/images/indicators/' + indicator + '.png'}}),_c('div',{staticClass:"target-description",domProps:{"innerHTML":_vm._s(_vm.indicators[indicator].targetDescription)}})])}),_vm._m(0)],2):_vm._e(),_c('h3',[_vm._v("Area")]),_c('div',{attrs:{"id":"explore-area"}},[_c('div',{attrs:{"id":"area-picture"}},[_c('cld-context',{attrs:{"cloudName":_vm.cloudName,"secure":"true"}},[(_vm.scenario.images && _vm.scenario.images.length > 0)?_c('cld-image',{attrs:{"public-id":_vm.scenario.images[0]._id,"height":"200","width":"423","crop":"scale"}}):_vm._e()],1)],1),_c('div',{attrs:{"id":"map-wrapper"}},[_c('map-widget',{attrs:{"objects":_vm.objects,"displayOnly":_vm.areas,"boundaries":_vm.boundaries,"editable":false,"id":_vm.mapId,"defaultZoom":13,"rotate":_vm.scenario.rotate,"height":200,"width":423}})],1)]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"white",on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.startOver($event)}}},[_vm._v("Back")]),_c('button',{on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.next($event)}}},[_vm._v("Next")])])]):_vm._e(),_c('main-layout')],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"indicator satisfaction"},[_c('img',{attrs:{"src":"/images/indicators/satisfaction.png"}}),_c('div',{staticClass:"target-description"},[_vm._v(" Increase stakeholder satisfaction using the area. ")])])}]

export { render, staticRenderFns }