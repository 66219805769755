<template>
  <div v-if="scenario"
    class="main-layout scenario-details"
    v-bind:style="{
      background:
        'linear-gradient(180deg, rgba(255,255,255,0), rgba(255, 255,255,1) 20%), url(\'/images/background/' +
        scenario.color +
        '.jpg\') repeat-x',
    }"
  >
    <div class="usermenu left">
      <a href="#" class="link" @mousedown.prevent="startOver">Back</a>
    </div>
    <div id="details-main" v-if="evaluation">
      <h1>
        {{ scenario.title }}: Indicators, baseline and targets for
        {{ evaluation.years[evaluation.years.length - 1] }}
      </h1>

      <div class="description" v-html="description">
      </div>

      <div v-if="evaluation" class="baseline indicators">
        <div
          :class="'indicator ' + indicator"
          v-for="indicator in displayedIndicators"
          v-bind:key="indicator"
        >
          <div class="heading">
            <h4>{{ indicators[indicator].name }}</h4>
          </div>

          <div class="charts">
            <line-chart
              ref="chart"
              class="chart"
              :chart-data="
                evaluation.baselineWithTargetDatacollection(
                  indicator,
                  evaluation.baselineDatacollection(indicator, null),
                  true
                )
              "
              :options="evaluation.chartOptions(indicator, true)"
              :width="425"
              :height="205"
            ></line-chart>
          </div>
        </div>
      </div>

      <h3>Targets to address the problems</h3>

      <div v-if="evaluation" class="targets">
        <div
          :class="'indicator ' + indicator"
          v-for="(indicator, index) in displayedIndicators"
          v-bind:key="index"
        >
          <img :src="'/images/indicators/' + indicator + '.png'" />
          <div
            class="target-description"
            v-html="indicators[indicator].targetDescription"
          ></div>
        </div>
        <div class="indicator satisfaction">
          <img src="/images/indicators/satisfaction.png" />
          <div class="target-description">
            Increase stakeholder satisfaction using the area.
          </div>
        </div>
      </div>

      <h3>Area</h3>

      <div id="explore-area">
        <div id="area-picture">
          <cld-context :cloudName="cloudName" secure="true">
            <cld-image
              v-if="scenario.images && scenario.images.length > 0"
              :public-id="scenario.images[0]._id"
              height="200"
              width="423"
              crop="scale"
            />
            <!-- <img :src="`/images/site/${scenario.site._id}.jpg`" width="423" height="200"/> -->
          </cld-context>
        </div>

        <div id="map-wrapper">
          <map-widget
            :objects="objects"
            :displayOnly="areas"
            :boundaries="boundaries"
            :editable="false"
            :id="mapId"
            :defaultZoom="13"
            :rotate="scenario.rotate"
            :height="200"
            :width="423"
          />
        </div>
      </div>
      <div class="buttons">
        <button @mousedown.stop.prevent="startOver" class="white">Back</button>
        <button @mousedown.stop.prevent="next">Next</button>
      </div>
    </div>

    <main-layout></main-layout>
  </div>
</template>

<script>
import MainLayout from "../components/MainLayout";
import MapWidget from "../components/MapWidget";
import Evaluation from "../evaluation";
import LineChart from "../components/lineChart";
import * as turf from "@turf/turf";
import { CldContext, CldImage } from "cloudinary-vue";

export default {
  components: { MapWidget, MainLayout, LineChart, CldContext, CldImage },
  data() {
    return {
      solution: null,
      scenario: null,
      challenges: [],
      boundaries: null,
      objects: { type: "FeatureCollection", features: [] },
      areas: { type: "FeatureCollection", features: [] },
      indicators: [],
      baseline: [],
      evaluation: null,
      mapId: "map-soluton-" + this.$store.state.solution._id,
      siteZoom: 13,
      displayedIndicators: [],
      cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD,
    };
  },

  created: async function () {
    if (this.$store.state.code) {
      this.solution = await this.$store.dispatch("loadSolutionByCode", this.$store.state.code);
      // Cache solution metadata for timeline hack
      this.$store.commit("setSolution", this.solution)
    } else {
      this.solution = this.$store.state.solution
    }

    this.scenario = await this.$store.dispatch("loadScenario", this.solution.scenario)
      this.solution.scenario = this.scenario

    //#this.solution.scenario = this.$store.state.scenario;
    this.evaluation = new Evaluation(
      this.solution,
      this.$store.state.currentProject.nbsTypes,
      this.$store.state.currentProject.indicators
    );
    this.evaluation.year = this.scenario.startYear || new Date().getFullYear();
    const site = this.scenario.site;

    this.indicators = this.evaluation.indicators;
    this.displayedIndicators =
      this.scenario.keyIndicators &&
      this.scenario.keyIndicators.length > 0
        ? this.scenario.keyIndicators
        : Object.values(this.scenario.indicators)
            .filter((i) => {
              return !this.indicators[i._id].economicScore;
            })
            .map((i) => {
              return i._id;
            });
    this.boundaries = turf.lineToPolygon(
      turf.lineString(site.boundaries.geometry.coordinates[0]),
      { autoComplete: true }
    );

    this.areas = site.areas || { type: "FeatureCollection", features: [] };
    this.challenges = site.challenges;
    this.siteZoom = site.zoom || 13;
    this.description = '<p>' +
      this.solution.scenario.description.replace(/<.+?>/, '').replace(/\n\n/g, '</p><p>') +
      '</p>';
  },
  methods: {
    next() {
      this.$store.commit("setCurrentPage", "/ImplementationGame");
      this.$router.push({
        path: "/ImplementationGame",
      });
    },
    startOver() {
      this.$store.commit("setScenario", null);
      this.$store.commit("setCurrentPage", "/SelectScenario");
      this.$router.push({
        path: "/SelectScenario",
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/indicators.scss";

.scenario-details h3,
.scenario-details h4 {
  text-align: center;
  margin: 20px 0;
}

.mapboxgl-map {
  height: 450px;
  margin: 20px 0;
}

.scenario-details .description {
  margin: 60px 0 30px 0;
}
.scenario-details .description,
.scenario-details .target_description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  color: rgba(43, 77, 55, 0.7);
}

.chart {
  position: relative;
}
.chart canvas {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
}

.peopleVisiting .chart:before {
  width: 345px;
}

.chart:before {
  content: "";
  position: absolute;
  top: 0;
  right: 10px;
  width: 372px;
  height: 157px;
  opacity: 0.7;
  z-index: 100;
  background: #fdefe8;
  border-radius: 10px;
}

.scenario-details button {
  line-height: 24px;
  padding: 14px 40px;
  margin: 0 10px;
}

.baseline {
  display: flex;
  justify-content: space-between;
}

.targets {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: wrap;
}
.indicators {
  justify-content: space-between;
  display: flex;
  flex-flow: wrap;
}
.targets .indicator {
  flex: 1 1 2;
  flex-basis: 44%;
  display: flex;
  justify-content: space-between;
  margin: 20px 10px;
}

.targets .indicator > div {
  flex: 1 3;
  align-items: center;
}
.targets .satisfaction {
  background: rgba(51, 172, 95, 0.1);
  border-radius: 15px;
  padding: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.targets .indicator img {
  margin-right: 20px;
}

.scenario-details .chart {
  margin-left: -25px;
}

#details-main {
  padding: 80px;
  max-width: 890px;
  margin: auto;
  padding-bottom: 330px;
}

#details-main .buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

#details-main .mapboxgl-map {
  border-radius: 10px;
  margin: 0;
}
.scenario-details h3 {
  @include futura-bold;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-transform: uppercase;
}

.scenario-details {
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% auto;
}
#details-main #explore-area {
  display: flex;
  justify-content: space-between;
}
</style>
